const contract = {
  'contract.expire': 'Thời hạn',
  'contract.code': 'Mã hợp đồng',
  'contract.info': 'Thông tin hợp đồng',
  'contract.create': 'Tạo hợp đồng',
  'contract.list': 'Danh sách hợp đồng',
  'contract.edit': 'Cập nhật hợp đồng',
  'contract.trial': 'Dùng thử (ngày)',
  'contract.trial.7': '7 ngày',
  'contract.trial.15': '15 ngày',
  'contract.delete': 'Xóa hợp đồng',
  'contract.manage': 'Quản lý hợp đồng',
  'contract.create.confirm': 'Xác nhận tạo hợp đồng',
  'contract.create.confirmSure': 'Bạn chắc chắn tạo hợp đồng?',
  'contract.edit.confirm': 'Xác nhận cập nhật hợp đồng',
  'contract.edit.confirmSure': 'Bạn chắc chắn cập nhật hợp đồng?',
  'contract.create.success': 'Tạo hợp đồng thành công',
  'contract.update.success': 'Cập nhật hợp đồng thành công',
  'contract.field': 'Lĩnh vực',
  'contract.price': 'Chi phí HD',
  'contract.active': 'Duyệt hợp đồng',
  'contract.active.confirm': 'Xác nhận duyệt hợp đồng',
  'contract.active.confirmSure': 'Bạn chắc chắn duyệt hợp đồng?',
  'contract.active.success': 'Duyệt hợp đồng thành công',
  'contract.status': 'Trạng thái HD',
  'contract.new': 'Mới',
  'contract.accepted': 'Đã duyệt',
  'contract.denied': 'Đã từ chối',
  'contract.expiry': 'Sắp hết hạn hd',
  'contract.expired': 'Đã hết hạn hd',
  'contract.id_front': 'CCCD mặt trước',
  'contract.id_back': 'CCCD mặt sau',
  'contract.user-process': 'Người xử lý',
  'contract.histories': 'Lịch sử duyệt hợp đồng',
  'contract.package': 'Gói dịch vụ',
  // validate

  'contract.name.required': 'Vui lòng nhập tên khách hàng',
  'contract.field.required': 'Vui lòng nhập lĩnh vực',
  'contract.package.required': 'Vui lòng chọn gói dịch vụ',
  'contract.price.required': 'Vui lòng nhập chi phí hợp đồng',
  'contract.id_card.required': 'Vui lòng nhập CCCD',
  'contract.id_card_date.required': 'Vui lòng nhập ngày cấp',
  'contract.from_date.required': 'Vui lòng nhập ngày bắt đầu',
  'contract.to_date.required': 'Vui lòng nhập ngày kết thúc',
};

const vnLangContract = {
  ...contract,
};

export default vnLangContract;
