import vnLangDashboard from './Personal/Dashboard/vn';
import vnLangEmployee from './Personal/Employee/vn';
import vnLangPermission from './Personal/Permission/vn';
import vnLangRole from './Personal/Role/vn';
import vnLangShop from './Personal/Shop/vn';
import vnLangStaff from './Personal/Staff/vn';
import vnLangWorkTime from './Personal/WorkTime/vn';
import vnLangWorkOff from './Personal/WorkOff/vn';
import vnLangWorkConfig from './Personal/WorkConfig/vn';
import vnLangSalary from './Personal/Salary/vn';
import vnLangSell from './Personal/Sell/vn';
import vnLangSaler from './Personal/Saler/vn';
import vnLangContract from './Personal/Contract/vn';
import vnLangCustomer from './Personal/Customer/vn';
import vnLangProduct from './Personal/Product/vn';
import vnLangSale from './Personal/Sale/vn';
import vnLangOrder from './Personal/Order/vn';
import vnLangConfig from './Personal/Config/vn';
import vnLangReport from './Personal/Report/vn';
import vnLangCashier from './Personal/Cashier/vn';
import vnLangKitchen from './Personal/Kitchen/vn';

const errorMsg = {
  //
  'error.email-empty': 'Please enter email address',
  'error.email-invalid': 'Invalid email address',
  //
  'error.password-empty': 'Please enter password',
  'error.password-length': 'Value must be longer than 4 characters',
  'error.password-format':
    'Value a least one character upper case letter, lower case letter, number and special character',
  'error.password-number': 'Value a least one character number',
  'error.password-special': 'Value a least one special character ',
  'error.password-capital': 'Value a least one character upper case letter',
  'error.password-lower': 'Value a least one character lower case letter',
  //
  'error.id-employee-empty': 'Please enter employee id',
  //
  'error.full-name-empty': 'Please enter full name',
  //
  'error.birthday-empty': 'Please enter birthday',
  //
  'error.id-personal-empty': 'Please enter ID',
  'error.id-personal-invalid': 'ID must be have 10 characters',
  'error.id-personal-date': 'Please enter ID date',
  'error.id-personal-place': 'Please enter ID issuer',
  //
  'error.phone-number-empty': 'Please enter phone number',
  'error.phone-number-invalid': 'Phone number must be have 10 characters',
  //
  'error.address-empty': 'Please enter address',
  //
  'error.start-date-work-empty': 'Please enter date start work',
  //
  'error.cv-status-empty': 'Please enter application status',
  //
  'error.account-status-empty': 'Please enter account status',
};

const authPage = {
  'auth.login-title': 'Login page',
  'auth.label-email': 'E-mail',
  'auth.label-password': 'Password',
  'auth.forgot-password-link': 'Forgot password?',
  'auth.login-button': 'Sign in',
  'auth.login-error-title': 'Login Error',
  'auth.login-error-msg': 'Email or password is incorrect',

  //
  'auth.login.helmet-title': 'Đăng nhập',
};

const topNav = {
  'sidebar.dropdown.account': 'Account',
  'sidebar.dropdown.change-password': 'Change password',
  'sidebar.dropdown.support': 'Support',
  'sidebar.dropdown.sign-out': 'Sign out',
  'topnav.dropdown.change-team.title': 'Danh sách team',
  'topnav.dropdown.item.personal': 'Tài khoản cá nhân',
  'topnav.noti.change-current-team.request': 'Đang chuyển không gian làm việc',
  'topnav.noti.change-current-team.failure':
    'Chuyển không gian làm việc thất bại',
};

const sidebar = {
  //
  'sidebar.dashboard': 'Dashboard',
  'sidebar.welcome': 'Welcome',
  //
  'sidebar.employee': 'Employee Manager',
  'sidebar.list-employees': 'List employees',
  'sidebar.add-employee': 'Add employee',
  //
  'sidebar.docs': 'Docs',
  'sidebar.dropdown.bookVacation': 'Xin nghỉ phép',
  'sidebar.dropdown.bookSchedule': 'Lịch công tác',
  'sidebar.warehouse': 'Danh sách kho',
  'sidebar.action-warehouse': 'Nhập/ xuất kho',
  'sidebar.discount': 'Giảm giá',
  'sidebar.import': 'Nhập kho',
  'sidebar.export': 'Xuất kho',
  'sidebar.warehouse.manager': 'Quản lý kho',
  'sidebar.warehouse.meko': 'Tồn kho',
  'sidebar.warehouse.check': 'Kiểm tra tồn kho',

  //

  //
  'sidebar.footer-action.create': 'Tạo đơn',
  'sidebar.footer-action.list': 'Đơn hàng',
  'sidebar.footer-action.dashboard': 'Trang chủ',
  'sidebar.footer-action.revenue': 'Doanh thu',
  'sidebar.constants-dashboard': 'Trang chủ',
  'sidebar.constants-order': 'Quản lý đơn hàng',
  'sidebar.constants-create-order': 'Tạo đơn hàng',
  'sidebar.constants-dispatcher-order': 'Điều phối đơn hàng',
  'sidebar.constants-list-order': 'List all orders',
  'sidebar.constants-order-tracked': 'Đơn hàng cần theo dõi',
  'sidebar.constants-list-order-app': 'Đơn hàng app',
  'sidebar.constants-user': 'Người dùng',
  'sidebar.sale-page': 'Trang bán hàng',
  'sidebar.sell': 'Bán hàng',
  'sidebar.product': 'Sản phẩm',
  'sidebar.unit': 'Đơn vị tính',
  'sidebar.category': 'Danh mục sản phẩm',
  'sidebar.packageBought': 'Gói đã mua',
  'sidebar.supplier': 'Nhà cung cấp',
  'sidebar.constants-customer': 'Khách hàng',
  'sidebar.constants-customer-app': 'Khách hàng app',
  'sidebar.constants-employees': 'Nhân viên',
  'sidebar.constants-salers': 'Nhân viên kinh doanh',
  'sidebar.constants-customers': 'Khách hàng',
  'sidebar.constants-role': 'Vai trò',
  'sidebar.constants-permissions': 'Phân quyền',
  'sidebar.constants-work-place': 'Địa điểm',
  'sidebar.constants-timekeeping': 'Chấm công',
  'sidebar.constants-shop-expire-soon': 'Cửa hàng sắp hết hạn',
  'sidebar.constants-business-schedule': 'Lịch công tác',
  'sidebar.constants-vacation-schedule': 'Lịch nghỉ phép',
  'sidebar.constants-config-check-in': 'Cấu hình điểm danh',
  'sidebar.constants-admin': 'Quản trị',
  'sidebar.constants-notification': 'Thông báo',
  'sidebar.constants-shipping': 'Đơn vị vận chuyển',
  'sidebar.constants-report': 'Quản lý báo cáo',
  'sidebar.constants-revenue': 'Doanh thu',
  'sidebar.constants-inventory': 'Tồn kho',
  'sidebar.constants-sub-shipping': 'Đơn vị vận chuyển',
  'sidebar.constants-region-group': 'Khu vực',
  'sidebar.constants-place': 'Địa điểm',
  'sidebar.constants-product': 'Quản lý sản phẩm',
  'sidebar.constants-list-product': 'Danh sách sản phẩm',
  'sidebar.constants-list-product-types': 'Danh sách loại sản phẩm',
  'sidebar.constants-list-product-price': 'Đơn giá',
  'sidebar.constants-manage-accumulation': 'Quản lý tích điểm',
  'sidebar.constants-blog': 'Bài viết',
  'sidebar.constants-voucher': 'Voucher',
  'sidebar.constants-gift': 'Quà tặng',
  'sidebar.constants-config': 'Cấu hình',
  'sidebar.constants-call-center': 'Call center',
  'sidebar.constants-label': 'Nhãn (Loại)',
  'sidebar.constants-source': 'Nguồn',
  'sidebar.constants-feedback': 'Feedback',
  'sidebar.constants-data': 'Dữ liệu',
  'sidebar.constants-log': 'Nhật ký',
  'sidebar.constants-customer-care': 'Khách hàng cần chăm sóc',
  'sidebar.constants-warehouse': 'Quản lý kho',
  'sidebar.constants-shop': 'Cửa hàng',
  'sidebar.constants-work-time': 'Ca làm',
  'sidebar.constants-shop-member': 'Thành viên',
  'sidebar.constants-work-off': 'Lịch nghỉ',
  'sidebar.constants-salary': 'Bảng lương',
  'sidebar.list-import-export': 'Danh sách nhập xuất',
  'sidebar.lookup-product': 'Tra cứu sản phẩm',
  'sidebar.constants-payroll': 'Bảng lương',
  'sidebar.order-list': 'Danh sách đơn hàng',
  'sidebar.order-return': 'Trả đơn hàng',
  'sidebar.table-group': 'Khu vực bàn',
  'sidebar.table': 'Danh sách bàn',
  'sidebar.list-vat': 'Danh sách xuất hóa đơn',
  'sidebar.config': 'Cấu hình',
  'sidebar.package': 'Gói dịch vụ',
  'sidebar.feature': 'Chức năng',
  'sidebar.report': 'Quản lý báo cáo',
  'sidebar.report-order': 'Báo cáo đơn hàng',
  'sidebar.report-order-cancel': 'Báo cáo đơn hàng hủy',
  'sidebar.report-staff': 'Báo cáo nhân viên',
  'sidebar.payment': 'Phương thức thanh toán',
  'sidebar.transaction': 'Giao dịch',
  'sidebar.kitchen': 'Bếp',
  'sidebar.kitchen.alarm': 'Quản lý âm thanh',
};

const user = {
  'user.currentPassword': 'Current password',
  'user.newPassword': 'New password',
  'user.confirmNewPassword': 'Confirm password',
  'user.changePasswordButton': 'Change password',
  'user.changePasswordSuccess': 'Change password success',
  'user.currentPassword.notValid': 'Current password is incorrect',
  'user.information': 'Thông tin cá nhân',
  'user.schedule': 'Lịch',
  'user.work-tracking': 'Hoạt động',
  'user.schedule.date-from': 'Ngày bắt đầu',
  'user.schedule.time-from': 'Giờ bắt đầu',
  'user.schedule.date-to': 'Ngày kết thúc',
  'user.schedule.time-to': 'Giờ kết thúc',
  'user.schedule-info': 'Lịch công tác',
  'user.vacation': 'Lịch nghỉ phép',
  'user.work-tracking.late': 'Trễ',
  'user.work-tracking.ok': 'Đúng giờ',
  'user.work-tracking.early': 'Sớm',
  'user.work-tracking.notMatch': 'Không đúng địa điểm',
  'user.work-tracking.notMatch.late': 'Không đúng địa điểm và trễ',
  'user.timekeeping': 'Thống kê giờ công',
  'user.workTracking': 'Lịch sử chấm công',
  'user.profile.account-disable': 'Không hoạt động',
  'user.profile.account-enable': 'Đang hoạt động',
  'user.config': 'Cấu hình nhân viên',
  'user.config.manager': 'Quản lý cấu hình nhân viên',
  'user.config.accounting': 'Kế toán trưởng',
  //
  //
  'user.user-checkout.confirm-checkout': 'Xác nhận ra về?',
  'user.user-checkout.confirm': 'Xác nhận',
  // Change password
  'user.user-change.change-password': 'Đổi mật khẩu',
  'user.user-change.current-password': 'Mật khẩu hiện tại',
  'user.user-change.new-password': 'Mật khẩu mới',
  'user.user-change.confirm-password': 'Xác nhận mật khẩu',
  'user.user-change.apply': 'Áp dụng',
  'user.user-change.cancel': 'Hủy bỏ',
  'user.user-change.success': 'Đổi thành công',
  'user.user-change.must-characters': '8-20 ký tự',
  'user.user-change.lower-character': 'Ít nhất một chữ thường',
  'user.user-change.upper-character': 'Ít nhất một chữ cái hoa',
  'user.user-change.one-character-number': 'Ít nhất một số',
  'user.user-change.one-special-character': 'Ít nhất một ký tự đặc biệt',
  'user.user-profile.full-name': 'Họ và tên',
  'user.user-profile.enter-full-name': 'Vui lòng nhập họ và tên',
  'user.user-profile.check-characters': 'Ít nhất 4 ký tự',
  'user.user-profile.email-required': 'Email bắt buộc phải nhập',
  'user.user-profile.email-not-correct': 'Email không đúng định dạng',
  'user.user-profile.phone-not-valid': 'Số điện thoại không đúng định dạng',
  'user.user-profile.phone-check-characters': 'Số điện thoại phải có 10 số',
  'user.user-profile.phone-check-characters-again': 'Số điện thoại phải có 10 số',
  'user.user-profile.status-account': 'Trạng thái tài khoản:',
  'user.user-profile.start-date': 'Ngày bắt đầu làm việc:',
  'user.user-profile.status-profile': 'Trạng thái hồ sơ:',
  'user.user-profile.position': 'Chức vụ:',
  'user.user-profile.update-failed': 'Cập nhật thông tin không thành công',
  'user.user-profile.update-information': 'Cập nhật thông tin cá nhân',
  'user.user-profile.save': 'Lưu',
  'user.user-profile.back': 'Quay lại',
  'user.user-contact.contact-information': 'Thông tin liên hệ',
  'user.user-contact.email-required': 'Email bắt buộc phải nhập',
  'user.user-contact.email-not-correct': 'Email không đúng định dạng',
  'user.user-contact.phone-number': 'Số điện thoại:',
  'user.user-contact.information-not-provided': 'Thông tin chưa được cung cấp',
  'user.user-contact.phone-not-valid': 'Số điện thoại không đúng định dạng',
  'user.user-contact.phone-must-characters': 'Số điện thoại phải có 10 số',
  'user.user-contact.phone-must-characters-again': 'Số điện thoại phải có 10 số',
  'user.user-contact.zalo-information-not-provided': 'Thông tin chưa được cung cấp',
  'user.user-contact.facebook-information-not-provided': 'Thông tin chưa được cung cấp',
  'user.user-profile.enter-current-password': 'Vui lòng nhập mật khẩu hiện tại.',
  'user.user-profile.enter-new-password': 'Vui lòng nhập mật khẩu mới.',
  'user.user-profile.password-must-characters': 'Mật khẩu ít nhất có 5 ký tự!',
  'user.user-profile.password-one-typical-character': 'Mật khẩu có ít nhất 1 ký tự in thường!',
  'user.user-profile.password-one-floral-character': 'Mật khẩu có ít nhất 1 ký tự in hoa!',
  'user.user-profile.password-one-number': 'Mật khẩu có ít nhất 1 ký tự số!',
  'user.user-profile.password-must-special-character': 'Mật khẩu có ít nhất 1 ký tự đặc biệt!',
  'user.user-profile.password-confirm-required': 'Bắt buộc xác nhận mật khẩu',
  'user.user-profile.password-must-match-new-password': ' Mật khẩu phải trùng khớp với mật khẩu mới đã nhập',

  // user-statusVacation
  'user.user-profile.waiting': 'Chờ duyệt',
  'user.user-profile.denied': 'Từ chối',
  'user.user-profile.paid-leave': 'Có lương',
  'user.user-profile.unpaid-leave': 'Không lương',
  // user profile
  // User profile
  'user.user-profile.profile-title': 'Trang cá nhân',
  'user.user-profile.update-avatar': 'Cập nhật',
  'user.user-profile.personal-information': 'Thông tin nhân sự',
  'user.user-profile.work-position': 'Chức vụ:',
  'user.user-profile.work-information-not-provided': 'Thông tin chưa được cung cấp',
  'user.user-profile.work-department': 'Phòng ban:',
  'user.user-profile.work-position-not-information': 'Thông tin chưa được cung cấp',
  'user.user-profile.work-start-date': 'Ngày bắt đầu làm việc:',
  'user.user-profile.work-start-date-not-information': 'Thông tin chưa được cung cấp',
  'user.user-profile.work-status-profile': 'Trạng thái hồ sơ:',
  'user.user-profile.work-status-profile-not-information': 'Thông tin chưa được cung cấp',
  'user.user-profile.work-status-account': 'Trạng thái tài khoản:',
  'user.user-profile.work-status-null': 'Thông tin chưa được cung cấp',
  'user.user-profile.work-status-working': 'Đang làm việc',
  'user.user-profile.work-status-missing': 'Đang vắng',
};

const table = {
  'table.title.name': 'Full name',
  'table.title.email': 'Email',
  'table.title.gender': 'Gender',
  'table.title.birthday': 'Birthday',
  //
  'table.image.list-products-name': 'Tên',
  'table.image.list-products-type': 'Loại',
  'table.image.list-products-empty': '(Trống)',

  'table.image.list-products-unit': 'Đơn vị',
  'table.image.list-product-types-name': 'Tên',
  'table.image.list-product-types-description': 'Description',
  'table.image.remaining-name': 'Tên',
  'table.image.remaining-phone': 'Số điện thoại',
  'table.thumb.list-products-name': 'Tên',
  'table.thumb.list-product-types-name': 'Tên',
  'table.thumb.list-product-types-description': 'Mô tả',
  'table.thumb.remaining-name': 'Tên',
  'table.thumb.remaining-phone': 'Số điện thoại',
  'table.thumb.type': 'Loại',
  'table.thumb.empty': '(Trống)',
  'table.thumb.unit': 'Đơn vị',
  'table.image.qua': 'Quả',
  'table.image.pack': 'Gói',
  'table.thumb.qua': 'Quả',
  'table.thumb.pack': 'Gói',
};

const common = {
  'common.list': 'Danh sách',
  'common.config': 'Cấu hình',
  'common.province': 'Tỉnh',
  'common.district': 'Quận',
  'common.ward': 'Huyện',
  'common.week': 'Tuần',
  'common.name_customer': 'Tên KH',
  'common.salers': 'NVKD',
  'common.accumulation': 'Tích lũy',
  'common.pay.salary': 'Thanh toán lương',
  'common.extend': 'Mở rộng',
  'common.payoff': 'Thưởng/phạt',
  'common.salary': 'Lương',
  'common.from-date': 'Từ ngày',
  'common.to-date': 'Đến ngày',
  'common.reason.required': 'Vui lòng nhập lý do',
  'common.user-confirm': 'Người xác nhận',
  'common.staff': 'Nhân viên',
  'common.staffBackup': 'Nhân viên thay thế',
  'common.timeFrom': 'Thời gian bắt đầu',
  'common.timeTo': 'Thời gian kết thúc',
  'common.password': 'Mật khẩu',
  'common.gender': 'Giới tính',
  'common.birthday': 'Sinh nhật',
  'common.avatar': 'Ảnh đại diện',
  'common.address': 'Địa chỉ',
  'common.phone': 'Số điện thoại',
  'common.username': 'Tên đăng nhập',
  'common.home': 'Trang chủ',
  'common.content-hi': 'Chào buổi',
  'common.notice': 'Thông báo',
  'common.createdAt': 'Ngày tạo ',
  'common.creator': 'Người tạo',
  'common.updatedAt': 'Ngày cập nhật',
  'common.date': 'Ngày',
  'common.selectAll': 'Chọn tất cả',
  'common.unSelectAll': 'Bỏ chọn tất cả',
  'common.accounting': 'Chứng từ kế toán',
  'common.status': 'Trạng thái',
  'common.status.qr': 'Trạng thái QR',
  'common.loading': 'Đang tải..',
  'common.disable': 'Vô hiệu hóa',
  'common.enable': 'Cho phép',
  'common.select': 'Lựa chọn',
  'common.selected': 'Đã chọn',
  'common.bigSizeImage': 'Quá lớn, xin vui lòng chọn một bức ảnh khác',
  'common.image': 'Hình ảnh',
  'common.note': 'Ghi chú',
  'common.description': 'Mô tả',
  'common.hours': 'Giờ',
  'common.employee.name': 'Tên nhân viên',
  'common.type': 'Loại',
  'common.mon': 'T2',
  'common.tue': 'T3',
  'common.wed': 'T4',
  'common.thur': 'T5',
  'common.fri': 'T6',
  'common.sat': 'T7',
  'common.sun': 'CN',
  'common.date.total': 'Tổng ngày',
  'common.name': 'Tên',
  'common.total': 'Tổng',
  'common.filter': 'Lọc',
  'common.filter.member': 'Thành viên',
  'common.reason': 'Lý do',
  'common.number': 'Số lượng',
  'common.money': 'Tổng tiền',
  'common.place-export': 'Nơi xuất',
  'common.place-export-receive': 'Nơi nhận',
  'common.code': 'Mã',
  'common.by': 'Theo',
  'common.from': 'từ',
  'common.to': 'cho',
  'common.shipping': 'Vận chuyển',
  'common.update.contemporary.success': 'Cập nhật đồng loạt thành công',
  'common.update.contemporary.failure': 'Cập nhật đồng loạt thất bại',
  'common.title': 'Tiêu đề',
  'common.month': 'Tháng',
  'common.year': 'Năm',
  'common.on': 'Bật',
  'common.off': 'Tắt',
  'common.search': 'Tìm kiếm',
  'common.city': 'Tỉnh',
  'common.transport': 'Chuyển qua',
  'common.transport.confirm': 'Xác nhận chuyển sang',
  'common.transport.confirmSure': 'Bạn chắc chắn chuyển sang',
  'common.copy.success': 'Sao chép thành công',
  'common.schedule': 'Nghỉ phép',
  'common.schedule.statistic': 'Thống kê nghỉ',
  'common.object': 'Đối tượng',
  'common.time': 'Thời gian',
  'common.rewarded': 'Khen thưởng',
  'common.honored': 'Tôn vinh',
  'common.active': 'Kích hoạt',
  'common.unactive': 'Chưa kích hoạt',
  'common.information.empty': 'Thông tin bị thiếu',
  'common.accept': 'Chấp nhận',
  'common.refuse': 'Từ chối',
  'common.pending': 'Đang chờ',
  'common.content': 'Nội dung',
  'common.update.place': 'Vui lòng cập nhật tỉnh thành(quận huyện) khách hàng',
  'common.discount.code': 'Mã giảm',
  'common.info.voucher': 'Thông tin voucher',
  'common.endow': 'Ưu đãi',
  'common.payment': 'Thanh toán',
  'common.score': 'Điểm',
  'common.day.online': 'Làm việc',
  'common.day.offline': 'Nghỉ',
  'common.turnOn': 'Bật',
  'common.turnOff': 'Tắt',
  'common.sale': 'Bán',
  'common.method': 'Hình thức',
  'common.fee': 'Phí',
  'common.ship': 'Ship',
  'common.price': 'Giá',
  'common.save.fee': 'Lưu phí',
  'common.box': 'Thùng',
  'common.cash': 'Chi phí',
  'common.totalCash': 'Tổng thu hộ',
  'common.canceled': 'Đã hủy',
  'common.history': 'Lịch sử',
  'common.none': 'Không',
  'common.accepted': 'Chấp nhận',
  'common.denied': 'Từ chối',
  //
  'common.dashboard': 'Trang chủ',
  'common.profile': 'Tài khoản',
  'common.list.empty': 'Dữ liệu trống',
  'common.check-in': 'Điểm danh',
  'common.logout': 'Đăng xuất',
  'common.account': 'Tài khoản',
  'common.change-password': 'Đổi mật khẩu',
  'common.sign-out': 'Đăng xuất',
  'common.dashboard-get-menu': 'Trang chủ',
  'common.language-month': 'vi',
  'common.language-year': 'vi',
  'common.loudspeaker': 'Bật tắt loa',
  'common.Checkout': 'Check out',
  'common.update': 'Cập nhật',
  'common.number.bag': 'Số bọc',
  'common.history.update': 'Lịch sử cập nhật',
  'common.egg': 'Trứng',
  'common.flour': 'Bột',
  'common.million': 'Triệu',
  'common.view-all': 'Xem tất cả',
  'common.news': 'Bản tin',
  'common.news.nothing': 'Không có bản tin nào',
  'common.supplier': 'Nhà cung cấp',
  'common.orders': 'Đơn hàng',
  'common.system': 'Hệ thống',
  'common.order-waiting.empty': 'Danh sách chờ hiện đang trống',
};

const dateFormat = {
  'date.today': 'Hôm nay',
  'date.yesterday': 'Hôm qua',
  'date.this-week': 'Tuần này',
  'date.last-week': 'Tuần trước',
  'date.this-month': 'Tháng này',
  'date.last-month': 'Tháng trước',
  'date.from-today': 'ngày từ hôm nay',
};

const sort = {
  'sort.asc': 'Tăng dần',
  'sort.desc': 'Giảm dần',
  'sort.more': 'Hơn',
  'sort.sortBy': 'Thứ tự',
  'sort.orderBy': 'Sắp xếp theo',
  'sort.search': 'Tìm kiếm...',
  'sort.employee': 'Sắp xếp theo nhân viên',
  'sort.month': 'Sắp xếp theo tháng',
  'sort.orderByEmployee': 'Sắp xếp theo nhân viên',
  'sort.date': 'Theo ngày',
  'sort.status': 'Trạng thái',
  'sort.staff': 'Nhân viên',
  'sort.warehouse': 'Kho',
  'sort.place': 'Chi nhánh',
  'sort.method': 'Hình thức',
};
const action = {
  'action.name': 'Hoạt động',
  'action.multi-excution': 'Thực thi hàng loạt',
};

const accounting = {
  'accounting.misa.create': 'Tạo chứng từ Misa',
  'accounting.misa.created': 'Đã tạo chứng từ Misa',
  'accounting.misa.canceled': 'Đã hủy chứng từ Misa',
  'accounting.misa.include-import': 'Tạo kèm chứng từ nhập hàng Misa',
};

const value = {
  'value.empty': 'Trống',
  'value.empty-list': 'Danh sách trống',
  'value.view': 'Xem',
  'value.total': 'Tổng cộng',
  'value.error.less.zero': 'Số lượng không thể bé hơn 0',
  'value.error.number': 'Vui lòng nhập số lượng',
  'value.phone.invalid': 'Số điện thoại không hợp lệ',
};

const btn = {
  'btn.confirm': 'Xác nhận',
  'btn.update': 'Cập nhật',
  'btn.close': 'Đóng',
  'btn.back': 'Trở lại',
  'btn.yes': 'Đồng ý',
  'btn.cancel': 'Hủy',
  'btn.save': 'Lưu',
  'btn.finish': 'Hoàn thành',
  'btn.get-price': 'Nhận báo giá',
  'btn.transport-order': 'Đẩy đơn ',
  'btn.save-config': 'Lưu cấu hình',
  'btn.claimPermission': 'Xin quyền',
  'btn.accept': 'Duyệt',
  'btn.add': 'Thêm',
  'btn.skip': 'Bỏ qua',
};

const personal = {
  'personal.check-in-success': 'Vào ca thành công',
  'personal.check-in-notice': 'Thông báo',
  'personal.checkout-success': 'Ra ca thành công',
  'personal.checkout-notice': 'Thông báo',
  'personal.current-password': 'Vui lòng nhập mật khẩu hiện tại',
  'personal.new-password': 'Vui lòng nhập mật khẩu mới',
  'personal.minimum-characters': 'Mật khẩu ít nhất có 5 ký tự!',
  'personal.lowercase-characters': 'Mật khẩu có ít nhất 1 ký tự in thường!',
  'personal.uppercase-characters': 'Mật khẩu có ít nhất 1 ký tự in hoa!',
  'personal.numeric-characters': 'Mật khẩu có ít nhất 1 ký tự số!',
  'personal.special-characters': 'Mật khẩu có ít nhất 1 ký tự đặc biệt!',
  'personal.confirm-new-password': 'Vui lòng nhập xác nhận mật khẩu mới',
  'personal.match-new-password': 'Mật khẩu phải trùng khớp với mật khẩu mới đã nhập',
  'personal.reset-password-successfully': 'Đặt lại mật khẩu thành công',
  'personal.reset-password-first': 'Đặt lại mật khẩu mới lần đầu đăng nhập',
  'personal.reset-password-failed': 'Đặt lại mật khẩu thất bại',
  'personal.reset-current-password': 'Mật khẩu hiện tại',
  'personal.reset-new-password': 'Mật khẩu mới',
  'personal.check-characters': '5-20 ký tự.',
  'personal.check-lowercase-letter': 'Ít nhất một chữ thường.',
  'personal.check-uppercase-letter': 'Ít nhất một chữ cái hoa.',
  'personal.check-numeric-character': 'Ít nhất một số.',
  'personal.check-special-character': 'Ít nhất một ký tự đặc biệt.',
  'personal.check-confirm-new-password': 'Nhập lại mật khẩu mới',
  'personal.components-order': 'Đơn hàng',
  'personal.components-default': 'Hệ thống',
  'personal.create-order-header': 'Tạo đơn hàng',
  'personal.personal.create-order-footer': 'Tạo đơn hàng',
  'personal.confirm-add-order': 'Xác nhận thêm đơn hàng',
  'personal.confirm-definitely': 'Bạn chắn chắc sẽ thêm đơn hàng này?',
  'personal.fill-information': 'Vui lòng điền thông tin người nhận',
  'personal.fill-phone-number': 'Số điện thoại',
  'personal.recipient-name': 'Tên người nhận',
  'personal.address': 'Địa chỉ',
  'personal.details': 'Chi tiết',
  'personal.confirm-product-order': 'Xác nhận xóa sản phẩm khỏi đơn hàng',
  'personal.confirm-definitely-product-order': 'Bạn chắn chắc muốn xóa sản phẩm này khỏi đơn hàng?',
  'personal.product-name': 'Nhập tên sản phẩm',
  'personal.fee': 'Phí ship',
  'personal.confirm-cancel-order': 'Xác nhận hủy đơn hàng',
  'personal.confirm-definitely-cancel-order': 'Bạn chắn chắc sẽ hủy đơn hàng này?',

  // Personal Infomation
  'personal.male': 'Nam',
  'personal.female': 'Nữ',
  'personal.unspecified': 'Tôi không muốn công khai',
  'personal.married': 'Kết hôn',
  'personal.single': 'Độc thân',
  'personal.personal-information': 'Thông tin cá nhân',
  'personal.personal-birthday': 'Ngày sinh:',
  'personal.information-not-provided': 'Thông tin chưa được cung cấp',
  'personal.gender': 'Giới tính:',
  'personal.marital-status': 'Tình trạng hôn nhân:',
  'personal.personal-id': 'CMND/CCCD:',
  'personal.personal-id-date': 'Ngày cấp:',
  'personal.personal-id-place': 'Nơi cấp:',
  'personal.personal-address': 'Địa chỉ:',
  'personal.personal-address-not-information': 'Thông tin chưa được cung cấp',
  'personal.personal-label-not-information': 'Thông tin chưa được cung cấp',
  'personal.gender-information-not-provided': 'Thông tin chưa được cung cấp',
  'personal.personal-telephone-not-information': 'Thông tin chưa được cung cấp',

  'personal.gender-male': 'Nam',
  'personal.gender-female': 'Nữ',
  'personal.gender-no-share': 'Tôi không muốn chia sẻ',

  'personal.marital-status-not-information': 'Thông tin chưa được cung cấp',
  'personal.marital-status-single': 'Độc thân',
  'personal.marital-status-married': 'Đã kết hôn',
  'personal.personal-id-not-information': 'Thông tin chưa được cung cấp',
  'personal.personal-id-date-not-information': 'Thông tin chưa được cung cấp',
  'personal.personal-id-place-not-information': 'Thông tin chưa được cung cấp',

};

const status = {
  'status-business.label-waiting': 'Chờ duyệt',
  'status-business.label-accepted': 'Đã duyệt',
  'status-business.label-denied': 'Từ chối',

  'status-vacation.label-waiting': 'Chờ duyệt',
  'status-vacation.label-denied': 'Từ chối',
  'status-vacation.label-paid-leave': 'Nghỉ phép có lương',
  'status-vacation.label-unpaid-leave': 'Nghỉ phép không lương',
};

const login = {
  'login.capcha-required': 'Vui lòng nhập mã bảo vệ',
  'login.capcha-incorrect': 'Mã bảo vệ không đúng',
  'login.password-required': 'Vui lòng nhập mật khẩu',
  'login.password-must-be-characters': 'Mật khẩu ít nhất có 5 ký tự',
  'login.email-required': 'Vui lòng nhập địa chỉ email',
  'login.invalid-email': 'Địa chỉ email của bạn không hợp lệ',

};

const register = {
  'register.shop-name-required': 'Vui lòng nhập tên shop',
  'register.phone-required': 'Vui lòng nhập số điện thoại',
  'register.phone-must-be-characters': 'Số điện thoại ít nhất 10 số',
  'register.email-required': 'Vui lòng nhập địa chỉ email',
  'register.invalid-email': 'Địa chỉ email của bạn không hợp lệ',
  'register.address-required': 'Vui lòng nhập địa chỉ',
  'register.account-success': 'Đăng ký tài khoản thành công',
  'register.back-page-login': 'Về trang đăng nhập',
  'register.account-notify-success': 'Chúc mừng bạn đã đăng ký tài khoản thành công',
  'register.account-title-account': 'Thông tin tài khoản như sau:',
  'register.account-name-shop': 'Tên cửa hàng:',
  'register.account-email': 'Email đăng nhập:',
  'register.account-password': 'Mật khẩu:',
  'register.account-required-login': 'Vui lòng đăng nhập tài khoản để thay đổi mật khẩu',

};

const forgetPassword = {
  'forgetPassword.continue': 'Tiếp tục',
  'forgetPassword.active.account': 'Kích hoạt tài khoản',
  'forgetPassword.back-to-home': 'Về trang chủ',
  'forgetPassword.change-password': 'Thay đổi mật khẩu',
  'forgetPassword.create-otp-again': 'Gửi lại OTP',

};
const schedule = {
  'schedule.modal-edit.confirm-update': 'Xác nhận cập nhật lịch công tác',
  'schedule.modal-edit.confirm-definitely-update': 'Bạn chắn chắc sẽ cập nhật lịch công tác này?',
  'schedule.modal.confirm-delete': 'Xác nhận xóa lịch nghỉ phép',
  'schedule.modal.confirm-definitely-delete': 'Bạn chắc chắn sẽ xóa lịch nghỉ phép này?',
};

const notfound = {
  'notfound.helmet.title': 'Không tìm thấy trang',
  'notfound.error-occurred': 'Rất tiếc... có vẻ như đã xảy ra lỗi!',
  'notfound.error-code': 'Mã lỗi',
  'notfound.back-home': 'Quay lại trang chủ',
};

const constants = {
  'constants.label-road': 'Road',
  'constants.label-plane': 'Máy bay',
  'constants.label-qua': 'Quả',
  'constants.label-pack': 'Gói',
  'constants.product-price-type.label-road': 'Đường bộ',
  'constants.product-price-type.label-plane': 'Máy bay',

};

const usLangLib = {
  ...btn,
  ...value,
  ...action,
  ...accounting,
  ...sort,
  ...errorMsg,
  ...authPage,
  ...topNav,
  ...sidebar,
  ...personal,
  ...status,
  ...login,
  ...schedule,
  ...notfound,
  ...constants,
  ...user,
  ...table,
  ...dateFormat,
  ...vnLangDashboard,
  ...vnLangEmployee,
  ...vnLangPermission,
  ...common,
  ...vnLangRole,
  ...vnLangShop,
  ...vnLangStaff,
  ...vnLangWorkTime,
  ...vnLangWorkOff,
  ...vnLangWorkConfig,
  ...vnLangSalary,
  ...vnLangSell,
  ...vnLangSaler,
  ...vnLangContract,
  ...vnLangCustomer,
  ...vnLangProduct,
  ...vnLangSale,
  ...vnLangOrder,
  ...vnLangConfig,
  ...vnLangReport,
  ...vnLangCashier,
  ...vnLangKitchen,
  ...register,
  ...forgetPassword,
};

export default usLangLib;
