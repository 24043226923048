const usLangWorkConfig = {
  'work-config.schedule.week': 'Scheduled work week',
  'work-config.schedule.month': 'Scheduled work month',
  'work-config.division': 'Division of work',
  'work-config.sidebar': 'Schedule',
  'work-config.sidebar.month': 'Schedule month',
  'work-config.hetmet': 'Working schedule - user management',
  'work-config.breadcrumb-title': 'List of work schedules',
  'work-config.breadcrumb-parent': 'Schedule',
  'work-config.btn-add': 'Add work schedules',
  'work-config.noti-create-success-title': 'Add a new work!',
  'work-config.noti-create-failure-title': 'Add new work not successful!Please try again later.',
  'work-config.noti-update-success-title': 'Update work successfully!',
  'work-config.noti-update-failure-title': 'Update work not successful!Please try again later.',
  'work-config.noti-delete-success-title': 'Delete a successful work!',
  'work-config.noti-delete-failure-title': 'Delete the work failed!Please try again later.',
  'work-config.modal-create-title': 'Add work schedules',
  'work-config.modal-create-btn': 'Add new',
  'work-config.modal-create-confirm-title': 'Confirmation of new work',
  'work-config.modal-create-confirm-sub-title': 'You definitely want to add this case?',
  'work-config.modal-update-title': 'Work schedule update',
  'work-config.modal-update-btn': 'Update',
  'work-config.modal-update-confirm-title': 'Confirmation of work schedule update',
  'work-config.modal-update-confirm-sub-title': 'You definitely want to update this schedule?',
  'work-config.modal-detail-title': 'Working details',
  'work-config.modal-detail-btn': 'Work schedule update',
  'work-config.modal-delete-title': 'Delete work',
  'work-config.modal-delete-btn': 'Delete',
  'work-config.modal-delete-confirm-title': 'Confirmation of work deletion',
  'work-config.modal-delete-confirm-sub-title': 'You definitely want to delete this case?',
  'work-config.label-user': 'Staff',
  'work-config.label-user.placeholder': 'All employees',
  'work-config.label-apply': 'Date of application',
  'work-config.label-apply.error': 'The application date must start tomorrow onwards!',
  'work-config.label-place': 'Work location',
  'work-config.label-place.placeholder': 'Select the working location',
  'work-config.label-place.required': 'Please select the working location!',
  'work-config.label-place.placeholder-2': 'Choose schedules to work during the day',
  'work-config.label-mon': 'Monday',
  'work-config.label-mon.err': 'Please choose a job for Monday!',
  'work-config.label-tue': 'Tuesday',
  'work-config.label-tue.err': 'Please choose to work for Tuesday!',
  'work-config.label-wed': 'Wednesday',
  'work-config.label-wed.err': 'Please choose a schedule for Wednesday!',
  'work-config.label-thu': 'Thursday',
  'work-config.label-thu.err': 'Please choose the work for Thursday!',
  'work-config.label-fri': 'Friday',
  'work-config.label-fri.err': 'Please choose the work for Friday!',
  'work-config.label-sat': 'Saturday',
  'work-config.label-sat.err': 'Please choose the work for Saturday!',
  'work-config.label-sun': 'Sunday',
  'work-config.label-sun.err': 'Please choose to work for Sunday!',

  'work-config.book': 'Add work schedule',
  'work-config.book.confirm': 'Confirm add work schedule',
  'work-config.book.confirmSure': 'You will definitely add work schedule',
  'work-config.change.success': 'Change work schedule success',
};

export default usLangWorkConfig;
