const vnLangReport = {
  'report.col.code': 'Mã HD',
  'report.col.name': 'Món ăn',
  'report.order.breadcrumb.title': 'Danh sách các món bị hủy',
  'report.staff.breadcrumb.title': 'Danh sách nhân viên hay hủy món',
  'report.col.date': 'Ngày',
  'report.col.total.name': 'Số lượng',
  'report.col.total-date.name': 'Tổng số lượng',
  'report.col.name.label': 'Nhân viên',
  'report.col.date.label': 'Thời gian',
  'report.col.time.label': 'Số lần hủy món',
  'report.col.total.label': 'Tổng số lần hủy món',
};

export default vnLangReport;
